import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'
import { resolveTileLink } from '/machinery/link'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { ShowRewardCalculatorPortalFlowButton } from '/features/buildingBlocks/reward-calculator/ShowRewardCalculatorPortalFlowButton'
import { Tile } from '/features/buildingBlocks/tile/Tile'
import { SliderButtons } from '/features/buildingBlocks/SliderButtons'

import styles from './TileSlider.css'

export function TileSlider({ tiles, linkRef }) {
  const isMounted = useRenderOnMount()
  const language = useLanguage()
  const { __ } = useTranslate()

  const slidesPerView = 1.2
  const sliderDisabled = slidesPerView >= tiles.length
  const showSliderButtons = !sliderDisabled && isMounted

  const [currentSlide, setCurrentSlide] = React.useState(0)

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: tiles?.length,
    slidesPerView,
    spacing: 16,
    initialSlide: 0,
    sliderDisabled: slidesPerView >= tiles.length,
    origin: 0,
    interactionTitle: 'tile-slider'
  })

  return (
    <div className={styles.component}>
      <ul ref={sliderRef} className={cx(styles.slider, sliderDisabled && styles.sliderDisabled, 'keen-slider')}>
        {tiles?.filter(item => item.representation).slice(0, 3).map((item, i) => (
          <li
            key={i}
            data-context-tile-index={i}
            className={cx(styles.slideContainer, 'keen-slider__slide')}
          >
            <Tile
              representation={item.representation}
              href={resolveTileLink({ tile: item, language })}
            />
          </li>
        ))}

        <li data-context-tile-index={3} className={cx(styles.slideContainer, 'keen-slider__slide')}>
          <ShowRewardCalculatorPortalFlowButton
            {...{ linkRef }}
            layoutClassName={styles.calculatorLayout}
            label={__`salary-checker`}
            buttonType='tile'
            dataX='open-calculator'
          />
        </li>
      </ul>

      {showSliderButtons && (
        <SliderButtons
          previousButtonDisabled={currentSlide === 0}
          nextButtonDisabled={currentSlide === (tiles?.length - Math.round(slidesPerView))}
          layoutClassName={styles.sliderButtonsLayout}
          {...{ currentSlide, handleClick }}
        />
      )}
    </div>
  )

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}
